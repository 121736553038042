<template>
  <el-form label-position="right" :model="configForm" label-width="120px" class="left">
    <div class="title">欢迎词</div>
    <el-divider></el-divider>
    <el-form-item label="空闲：">
      <el-input class="inputWidth1" v-model="configForm.welcomespeech.free_text" maxlength="15" placeholder="请输入空闲时展示文字" @input="(e) => (configForm.welcomespeech.free_text = validSpacing(e))"></el-input>
    </el-form-item>
    <div style="display:flex;">
      <el-form-item label="临停车：">
        <el-input class="inputWidth" v-model="configForm.welcomespeech.temporarycar_text" maxlength="15" :placeholder="`请输入临停车${nameForm}展示文字`" @input="(e) => (configForm.welcomespeech.temporarycar_text = validSpacing(e))"></el-input>
      </el-form-item>
      <el-form-item label="语音播报：">
        <el-select popper-class="my-select" clearable v-model="configForm.welcomespeech.temporarycar_voice" placeholder="请选择语音播报类型" class="voiceWidth">
          <el-option v-for="item in getOptions('VoiceType')" :key="item.value" :label="item.name" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="音量：">
        <el-select class="selectWidth" popper-class="my-select" clearable v-model="configForm.welcomespeech.temporarycar_volume" placeholder="请选择音量">
          <el-option v-for="item in volumeList" :key="item" :label="item" :value="item"></el-option>
        </el-select>
      </el-form-item>
    </div>
    <div style="display:flex;">
      <el-form-item label="计时卡：">
        <el-input class="inputWidth" v-model="configForm.welcomespeech.Time_text" maxlength="15" :placeholder="`请输入计时卡${nameForm}展示文字`" @input="(e) => (configForm.welcomespeech.Time_text = validSpacing(e))"></el-input>
      </el-form-item>
      <el-form-item label="语音播报：">
        <el-select popper-class="my-select" clearable v-model="configForm.welcomespeech.Time_voice" placeholder="请选择语音播报类型" class="voiceWidth">
          <el-option v-for="item in getOptions('VoiceType')" :key="item.value" :label="item.name" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="音量：">
        <el-select class="selectWidth" popper-class="my-select" clearable v-model="configForm.welcomespeech.Time_volume" placeholder="请选择音量">
          <el-option v-for="item in volumeList" :key="item" :label="item" :value="item"></el-option>
        </el-select>
      </el-form-item>
    </div>
    <div style="display:flex;">
      <el-form-item label="月租卡：">
        <el-input class="inputWidth" v-model="configForm.welcomespeech.Month_text" maxlength="15" :placeholder="`请输入月租卡${nameForm}展示文字`" @input="(e) => (configForm.welcomespeech.Month_text = validSpacing(e))"></el-input>
      </el-form-item>
      <el-form-item label="语音播报：">
        <el-select popper-class="my-select" clearable v-model="configForm.welcomespeech.Month_voice" placeholder="请选择语音播报类型" class="voiceWidth">
          <el-option v-for="item in getOptions('VoiceType')" :key="item.value" :label="item.name" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="音量：">
        <el-select class="selectWidth" popper-class="my-select" clearable v-model="configForm.welcomespeech.Month_volume" placeholder="请选择音量">
          <el-option v-for="item in volumeList" :key="item" :label="item" :value="item"></el-option>
        </el-select>
      </el-form-item>
    </div>
    <div style="display:flex;">
      <el-form-item label="储值卡：">
        <el-input class="inputWidth" v-model="configForm.welcomespeech.Stored_text" maxlength="15" :placeholder="`请输入储值卡${nameForm}展示文字`" @input="(e) => (configForm.welcomespeech.Stored_text = validSpacing(e))"></el-input>
      </el-form-item>
      <el-form-item label="语音播报：">
        <el-select popper-class="my-select" clearable v-model="configForm.welcomespeech.Stored_voice" placeholder="请选择语音播报类型" class="voiceWidth">
          <el-option v-for="item in getOptions('VoiceType')" :key="item.value" :label="item.name" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="音量：">
        <el-select class="selectWidth" popper-class="my-select" clearable v-model="configForm.welcomespeech.Stored_volume" placeholder="请选择音量">
          <el-option v-for="item in volumeList" :key="item" :label="item" :value="item"></el-option>
        </el-select>
      </el-form-item>
    </div>
    <div style="display:flex;">
      <el-form-item label="贵宾卡：">
        <el-input class="inputWidth" v-model="configForm.welcomespeech.vip_text" maxlength="15" :placeholder="`请输入贵宾卡${nameForm}展示文字`" @input="(e) => (configForm.welcomespeech.vip_text = validSpacing(e))"></el-input>
      </el-form-item>
      <el-form-item label="语音播报：">
        <el-select popper-class="my-select" clearable v-model="configForm.welcomespeech.vip_voice" placeholder="请选择语音播报类型" class="voiceWidth">
          <el-option v-for="item in getOptions('VoiceType')" :key="item.value" :label="item.name" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="音量：">
        <el-select class="selectWidth" popper-class="my-select" clearable v-model="configForm.welcomespeech.vip_volume" placeholder="请选择音量">
          <el-option v-for="item in volumeList" :key="item" :label="item" :value="item"></el-option>
        </el-select>
      </el-form-item>
    </div>
    <div class="title">屏显方案</div>
    <el-divider></el-divider>
    <el-row :gutter="20">
      <el-col :span="12">
        <el-form-item label="空闲显示：" style="margin-bottom:0px">
          <el-form-item label="第一行" label-width="50" style="margin-bottom:20px">
            <el-select popper-class="my-select" clearable v-model="configForm.screenpaper.showfree_one" placeholder="请选择空闲第一行文字">
              <el-option v-for="item in getOptions('ShowfreeType')" :key="item.value" :label="item.name" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="第二行" label-width="50" style="margin-bottom:20px">
            <el-select popper-class="my-select" clearable v-model="configForm.screenpaper.showfree_two" placeholder="请选择空闲第二行文字">
              <el-option v-for="item in getOptions('ShowfreeType')" :key="item.value" :label="item.name" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="第三行" label-width="50" style="margin-bottom:20px">
            <el-select popper-class="my-select" clearable v-model="configForm.screenpaper.showfree_three" placeholder="请选择空闲第三行文字">
              <el-option v-for="item in getOptions('ShowfreeType')" :key="item.value" :label="item.name" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="第四行" label-width="50" style="margin-bottom:20px">
            <el-select popper-class="my-select" clearable v-model="configForm.screenpaper.showfree_four" placeholder="请选择空闲第四行文字">
              <el-option v-for="item in getOptions('ShowfreeType')" :key="item.value" :label="item.name" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form-item>
        <el-form-item label="计时卡显示：" style="margin-bottom:0px">
          <el-form-item label="第一行" label-width="50" style="margin-bottom:20px">
            <el-select popper-class="my-select" clearable v-model="configForm.screenpaper.showTime_one" placeholder="请选择计时卡第一行文字">
              <el-option v-for="item in getOptions('ShowType')" :key="item.value" :label="item.name" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="第二行" label-width="50" style="margin-bottom:20px">
            <el-select popper-class="my-select" clearable v-model="configForm.screenpaper.showTime_two" placeholder="请选择计时卡第二行文字">
              <el-option v-for="item in getOptions('ShowType')" :key="item.value" :label="item.name" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="第三行" label-width="50" style="margin-bottom:20px">
            <el-select popper-class="my-select" clearable v-model="configForm.screenpaper.showTime_three" placeholder="请选择计时卡第三行文字">
              <el-option v-for="item in getOptions('ShowType')" :key="item.value" :label="item.name" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="第四行" label-width="50" style="margin-bottom:20px">
            <el-select popper-class="my-select" clearable v-model="configForm.screenpaper.showTime_four" placeholder="请选择计时卡第四行文字">
              <el-option v-for="item in getOptions('ShowType')" :key="item.value" :label="item.name" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form-item>
        <el-form-item label="月租卡显示：" style="margin-bottom:0px">
          <el-form-item label="第一行" label-width="50" style="margin-bottom:20px">
            <el-select popper-class="my-select" clearable v-model="configForm.screenpaper.showMonth_one" placeholder="请选择月租卡第一行文字">
              <el-option v-for="item in getOptions('ShowMonthType')" :key="item.value" :label="item.name" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="第二行" label-width="50" style="margin-bottom:20px">
            <el-select popper-class="my-select" clearable v-model="configForm.screenpaper.showMonth_two" placeholder="请选择月租卡第二行文字">
              <el-option v-for="item in getOptions('ShowMonthType')" :key="item.value" :label="item.name" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="第三行" label-width="50" style="margin-bottom:20px">
            <el-select popper-class="my-select" clearable v-model="configForm.screenpaper.showMonth_three" placeholder="请选择月租卡第三行文字">
              <el-option v-for="item in getOptions('ShowMonthType')" :key="item.value" :label="item.name" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="第四行" label-width="50" style="margin-bottom:20px">
            <el-select popper-class="my-select" clearable v-model="configForm.screenpaper.showMonth_four" placeholder="请选择月租卡第四行文字">
              <el-option v-for="item in getOptions('ShowMonthType')" :key="item.value" :label="item.name" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="临停车显示：" style="margin-bottom:0px">
          <el-form-item label="第一行" label-width="50" style="margin-bottom:20px">
            <el-select popper-class="my-select" clearable v-model="configForm.screenpaper.showTemporarycar_one" placeholder="请选择临停车第一行文字">
              <el-option v-for="item in getOptions('ShowType')" :key="item.value" :label="item.name" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="第二行" label-width="50" style="margin-bottom:20px">
            <el-select popper-class="my-select" clearable v-model="configForm.screenpaper.showTemporarycar_two" placeholder="请选择临停车第二行文字">
              <el-option v-for="item in getOptions('ShowType')" :key="item.value" :label="item.name" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="第三行" label-width="50" style="margin-bottom:20px">
            <el-select popper-class="my-select" clearable v-model="configForm.screenpaper.showTemporarycar_three" placeholder="请选择临停车第三行文字">
              <el-option v-for="item in getOptions('ShowType')" :key="item.value" :label="item.name" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="第四行" label-width="50" style="margin-bottom:20px">
            <el-select popper-class="my-select" clearable v-model="configForm.screenpaper.showTemporarycar_four" placeholder="请选择临停车第四行文字">
              <el-option v-for="item in getOptions('ShowType')" :key="item.value" :label="item.name" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form-item>
        <el-form-item label="储值卡显示：" style="margin-bottom:0px">
          <el-form-item label="第一行" label-width="50" style="margin-bottom:20px">
            <el-select popper-class="my-select" clearable v-model="configForm.screenpaper.showStored_one" placeholder="请选择储值卡第一行文字">
              <el-option v-for="item in getOptions('ShowStoredType')" :key="item.value" :label="item.name" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="第二行" label-width="50" style="margin-bottom:20px">
            <el-select popper-class="my-select" clearable v-model="configForm.screenpaper.showStored_two" placeholder="请选择储值卡第二行文字">
              <el-option v-for="item in getOptions('ShowStoredType')" :key="item.value" :label="item.name" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="第三行" label-width="50" style="margin-bottom:20px">
            <el-select popper-class="my-select" clearable v-model="configForm.screenpaper.showStored_three" placeholder="请选择储值卡第三行文字">
              <el-option v-for="item in getOptions('ShowStoredType')" :key="item.value" :label="item.name" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="第四行" label-width="50" style="margin-bottom:20px">
            <el-select popper-class="my-select" clearable v-model="configForm.screenpaper.showStored_four" placeholder="请选择储值卡第四行文字">
              <el-option v-for="item in getOptions('ShowStoredType')" :key="item.value" :label="item.name" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form-item>
        <el-form-item label="贵宾卡显示：" style="margin-bottom:0px">
          <el-form-item label="第一行" label-width="50" style="margin-bottom:20px">
            <el-select popper-class="my-select" clearable v-model="configForm.screenpaper.showVip_one" placeholder="请选择贵宾卡第一行文字">
              <el-option v-for="item in getOptions('ShowVipType')" :key="item.value" :label="item.name" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="第二行" label-width="50" style="margin-bottom:20px">
            <el-select popper-class="my-select" clearable v-model="configForm.screenpaper.showVip_two" placeholder="请选择贵宾卡第二行文字">
              <el-option v-for="item in getOptions('ShowVipType')" :key="item.value" :label="item.name" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="第三行" label-width="50" style="margin-bottom:20px">
            <el-select popper-class="my-select" clearable v-model="configForm.screenpaper.showVip_three" placeholder="请选择贵宾卡第三行文字">
              <el-option v-for="item in getOptions('ShowVipType')" :key="item.value" :label="item.name" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="第四行" label-width="50" style="margin-bottom:20px">
            <el-select popper-class="my-select" clearable v-model="configForm.screenpaper.showVip_four" placeholder="请选择贵宾卡第四行文字">
              <el-option v-for="item in getOptions('ShowVipType')" :key="item.value" :label="item.name" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    nameForm: { type: String },
    Configtype: { type: Number },
    joinConfigForm: { type: Object }
  },
  data () {
    return {
      volumeList: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      configForm: {
        welcomespeech: {
          free_text: '', // 空闲文字显示
          temporarycar_text: '', // 临停车文字显示
          temporarycar_voice: null, // 临停车语音播报
          temporarycar_volume: null, // 临停车播报音量
          Time_text: '', // 计时卡文字显示
          Time_voice: null, // 计时卡语音播报
          Time_volume: null, // 计时卡播报音量
          Month_text: '', // 月租卡文字显示
          Month_voice: null, // 月租卡语音播报
          Month_volume: null, // 月租卡播报音量
          Stored_text: '', // 储值卡文字显示
          Stored_voice: null, // 储值卡语音播报
          Stored_volume: null, // 储值卡播报音量
          vip_text: '', // 贵宾卡文字显示
          vip_voice: null, // 贵宾卡语音播报
          vip_volume: null // 贵宾卡播报音量
        },
        screenpaper: {
          showfree_one: null, // 空闲显示第1行
          showfree_two: null, // 空闲显示第2行
          showfree_three: null, // 空闲显示第3行
          showfree_four: null, // 空闲显示第4行
          showTemporarycar_one: null, // 临停车显示第1行
          showTemporarycar_two: null, // 临停车显示第2行
          showTemporarycar_three: null, // 临停车显示第3行
          showTemporarycar_four: null, // 临停车显示第4行
          showTime_one: null, // 计时卡显示第1行
          showTime_two: null, // 计时卡显示第2行
          showTime_three: null, // 计时卡显示第3行
          showTime_four: null, // 计时卡显示第4行
          showMonth_one: null, // 月租卡显示第1行
          showMonth_two: null, // 月租卡显示第2行
          showMonth_three: null, // 月租卡显示第3行
          showMonth_four: null, // 月租卡显示第4行
          showStored_one: null, // 储值卡显示第1行
          showStored_two: null, // 储值卡显示第2行
          showStored_three: null, // 储值卡显示第3行
          showStored_four: null, // 储值卡显示第4行
          showVip_one: null, // 贵宾卡显示第1行
          showVip_two: null, // 贵宾卡显示第2行
          showVip_three: null, // 贵宾卡显示第3行
          showVip_four: null // 贵宾卡显示第4行
        }
      }
    }
  },
  created () {
  },
  computed: {
    ...mapGetters(['getOptions'])
  },
  watch: {
    joinConfigForm (val, oldval) {
      //   console.log(val);
      if (val) {
        this.configForm = this.eachObject(val)
      }
    }
  },
  mounted () { },
  methods: {
    eachObject (obj) {
      //   console.log('父组件传过来的------', obj);
      for (const key in obj.welcomespeech) {
        if (key !== 'free_text' && key !== 'temporarycar_text' && key !== 'Time_text' && key !== 'Month_text' && key !== 'Stored_text' && key !== 'vip_text') {
          obj.welcomespeech[key] = obj.welcomespeech[key] === -1 ? null : obj.welcomespeech[key]
        }
      }
      for (const key in obj.screenpaper) {
        obj.screenpaper[key] = obj.screenpaper[key] === -1 ? null : obj.screenpaper[key]
      }
      return obj
    }
  }
}
</script>
<style scoped lang="less">
.left{
  height: 600px;
  overflow-y: auto;
}
.el-input {
  max-width: 300px;
}
.title {
  width: 100%;
  text-align: left;
  font-size: 16px;
  font-weight: 700;
  color: #000;
  // margin-top: 20px;
}
.inputWidth {
  width:100%;
}
.inputWidth1 {
  width:20.5%;
}
.selectWidth {
  width: 70%;
}
.voiceWidth{
  width: 100%;
}
</style>
