<template>
    <!-- 设备管理 - 显示屏设备配置 -->
    <div class="box-card" style="text-align: left;margin-top: 0;">
      <!-- 主要内容 -->
      <el-tabs v-model="activeName" @tab-click="handleClick" >
        <el-tab-pane label="车辆入场" name="first">
          <ConfigForm class="config_box" :joinConfigForm="joinConfigForm" v-if="joinConfigForm" :Configtype="1" nameForm="入场" ref="joinConfigForm"></ConfigForm>
        </el-tab-pane>
        <el-tab-pane label="车辆出场" name="second">
          <ConfigForm class="config_box" :joinConfigForm="outConfigForm" v-if="outConfigForm" :Configtype="2" nameForm="出场" ref="outConfigForm"></ConfigForm>
        </el-tab-pane>
      </el-tabs>
      <!-- <lbButton type="goBack" icon="back" class="checkButton2" @click="defaultForm">返 回</lbButton> -->
      <lbButton type="default" fill icon="confirm"  class="checkButton" @click="submitLayoutForm" v-preventReClick>保 存</lbButton>
    </div>
  </template>
<script>
import { addorUptDisplayDeviceConfig, getDisplayDeviceConfigDetail } from '@/api/deviceApi'
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
import ConfigForm from '../ledScreenConfigForm/ledScreenConfigForm.vue'
export default {
  props: ['id', 'name'],
  components: {
    ConfigForm
  },
  data () {
    return {
      // 当前展开的tab
      activeName: 'first',
      joinConfigForm: {},
      outConfigForm: {}
    }
  },
  created () {
    this.fnGetDisplayDeviceConfigDetail()
  },
  mounted () { },
  computed: {
    ...mapGetters(['getOptions'])
  },
  methods: {
    ...mapMutations(['setQueryStatus']),
    // 获取配置数据详情
    async fnGetDisplayDeviceConfigDetail () {
      const res = await getDisplayDeviceConfigDetail({
        _deviceid: this.id
      })
      // console.log('获取配置数据详情----', res.Data);
      if (res && res.Code === 200 && res.Data) {
        res.Data.forEach(item => {
          if (item.Configtype === 1) {
            this.joinConfigForm = item
          } else if (item.Configtype === 2) {
            this.outConfigForm = item
          }
        })
        // console.log('获取配置数据详情----', res.Data);
      }
    },
    // 返回车牌识别相机页面
    // goBack () {
    //   this.setQueryStatus(2)
    //   this.$router.go(-1)
    // },
    // 点击切换tab
    handleClick (tab, event) {
      // console.log(tab.label);
    },
    // 保存配置
    async submitLayoutForm () {
      var obj = JSON.parse(JSON.stringify(this.$refs.joinConfigForm.configForm))
      this.eachObject(obj)
      obj.Configtype = 1
      obj.DisplayScreenId = this.id
      obj.DisplayScreenName = this.name
      const res = await addorUptDisplayDeviceConfig(obj)
      if (res && res.Code === 200) {
        var obj1 = JSON.parse(JSON.stringify(this.$refs.outConfigForm.configForm))
        this.eachObject(obj1)
        obj1.Configtype = 2
        obj1.DisplayScreenId = this.id
        obj1.DisplayScreenName = this.name
        const res = await addorUptDisplayDeviceConfig(obj1)
        if (res && res.Code === 200) {
        //   this.goBack()
          this.$emit('closeDialog', false)
        }
      }
    },
    eachObject (obj) {
      for (const key in obj.welcomespeech) {
        if (key !== 'free_text' && key !== 'temporarycar_text' && key !== 'Time_text' && key !== 'Month_text' && key !== 'Stored_text' && key !== 'vip_text') {
          obj.welcomespeech[key] = obj.welcomespeech[key] ? obj.welcomespeech[key] : -1
        }
      }
      for (const key in obj.screenpaper) {
        obj.screenpaper[key] = obj.screenpaper[key] ? obj.screenpaper[key] : -1
      }
    },
    defaultForm () {
      this.$emit('closeDialog', false)
    }
  }
}
</script>
  <style scoped lang="less">
  .el-input {
    max-width: 300px;
  }
  .el-tabs {
    /deep/ .el-tabs__content {
      overflow: inherit !important;
    }
    /deep/ .el-tabs__item {
      font-size: 18px !important;
      font-weight: 700 !important;
    }
    /deep/ .el-tabs__nav-wrap::after {
      height: 1.5px;
      background-color: #f2f2f2;
    }
    /deep/ .el-tabs__active-bar {
      height: 1.5px;
    }
    /deep/ .el-tabs__item {
      border-right: none !important;
    }
  }
  .el-tab-pane {
    display: flex;
    flex-direction: column;
  }
  .title {
    width: 100%;
    text-align: left;
    font-size: 16px;
    font-weight: 700;
    color: #000;
    margin-top: 20px;
  }
  .inputWidth {
    width: 250px;
  }
  .selectWidth {
    width: 150px;
  }
  .config_box {
    max-height: 650px;
    overflow: hidden;
    overflow-y: auto;
  }
  .box-card {
    position: relative;
    padding-bottom: 30px;

    .checkButton {
        position: absolute;
        right: 0px;
    }

    .checkButton2 {
        position: absolute;
        right: 150px;
    }
}
  </style>
